define("ember-modal-dialog/templates/components/liquid-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "gkDuAwdG",
    "block": "[[[41,[33,1],[[[6,[39,2],null,[[\"stack\",\"value\",\"class\"],[[33,3],[33,4],[28,[37,5],[\"liquid-dialog-container \",[33,6],\" \",[33,7]],null]]],[[\"default\"],[[[[1,\"    \"],[10,0],[15,0,[29,[[36,6],\" \",[36,7]]]],[12],[1,\"\\n\"],[41,[33,8],[[[1,\"        \"],[10,0],[15,0,[36,9]],[15,\"onclick\",[28,[37,10],[[30,0],[33,11]],null]],[14,\"tabindex\",\"-1\"],[14,\"data-emd-overlay\",\"\"],[12],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[10,0],[15,0,[36,12]],[12],[1,\"\\n        \"],[18,1,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[]],[[[6,[39,2],null,[[\"stack\",\"value\",\"class\"],[[33,3],[33,4],[28,[37,5],[\"liquid-dialog-container \",[33,6],\" \",[33,7]],null]]],[[\"default\"],[[[[41,[33,8],[[[1,\"      \"],[10,0],[15,0,[36,9]],[15,\"onclick\",[28,[37,10],[[30,0],[28,[37,14],[[33,11]],null]],null]],[14,\"tabindex\",\"-1\"],[14,\"data-emd-overlay\",\"\"],[12],[1,\"\\n        \"],[10,0],[15,0,[36,12]],[12],[1,\"\\n          \"],[18,1,null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[15,0,[36,12]],[12],[1,\"\\n        \"],[18,1,null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]]],[]]]]]],[]]]],[\"&default\"],false,[\"if\",\"isOverlaySibling\",\"liquid-wormhole\",\"stack\",\"value\",\"concat\",\"wrapperClassNamesString\",\"wrapperClass\",\"hasOverlay\",\"overlayClassNamesString\",\"action\",\"onClickOverlay\",\"containerClassNamesString\",\"yield\",\"ignore-children\"]]",
    "moduleName": "ember-modal-dialog/templates/components/liquid-dialog.hbs",
    "isStrictMode": false
  });
});