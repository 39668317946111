define("ember-cli-notifications/components/notification-container", ["exports", "@ember/component", "@ember/object", "@ember/template", "@ember/service", "ember-cli-notifications/templates/components/notification-container"], function (_exports, _component, _object, _template, _service, _notificationContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _notificationContainer.default,
    position: 'top',
    notifications: (0, _service.inject)(),
    classNameBindings: ['computedPosition', ':ember-cli-notifications-notification__container'],
    attributeBindings: ['computedStyle:style', 'position:data-test-notification-container'],
    zindex: '1060',
    computedPosition: (0, _object.computed)('position', function () {
      return `ember-cli-notifications-notification__container--${this.get('position')}`;
    }),
    computedStyle: (0, _object.computed)('zindex', function () {
      return (0, _template.htmlSafe)(`z-index: ${this.get('zindex')};`);
    })
  });
});