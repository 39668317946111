define("ember-modal-dialog/components/in-place-dialog", ["exports", "@ember/component", "@ember/object", "ember-modal-dialog/templates/components/in-place-dialog"], function (_exports, _component, _object, _inPlaceDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */

  const computedJoin = function (prop) {
    return (0, _object.computed)(prop, function () {
      return this.get(prop).join(' ');
    });
  };
  var _default = _exports.default = _component.default.extend({
    tagName: '',
    layout: _inPlaceDialog.default,
    containerClass: null,
    // passed in
    containerClassNames: ['ember-modal-dialog', 'ember-modal-dialog-in-place', 'emd-in-place'],
    // set this in a subclass definition
    containerClassNamesString: computedJoin('containerClassNames'),
    concatenatedProperties: ['containerClassNames']
  });
});