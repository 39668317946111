define("ember-cli-notifications/services/notifications", ["exports", "@ember/service", "@ember/polyfills", "@ember/array", "@ember/object", "@ember/runloop", "ember-get-config"], function (_exports, _service, _polyfills, _array, _object, _runloop, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const notificationAssign = _polyfills.assign || _polyfills.merge;
  const globals = _emberGetConfig.default['ember-cli-notifications'] || {}; // Import app config object
  var _default = _exports.default = _service.default.extend({
    init() {
      this._super(...arguments);
      this.set('content', (0, _array.A)());
    },
    // Method for adding a notification
    addNotification(options) {
      // If no message is set, throw an error
      if (!options.message) {
        throw new Error("No notification message set");
      }
      const notification = _object.default.create({
        message: options.message,
        type: options.type || 'info',
        autoClear: options.autoClear ?? globals.autoClear ?? false,
        clearDuration: options.clearDuration ?? globals.clearDuration ?? 3200,
        onClick: options.onClick,
        htmlContent: options.htmlContent || false,
        cssClasses: options.cssClasses
      });
      this.content.pushObject(notification);
      if (notification.autoClear) {
        notification.set('remaining', notification.get('clearDuration'));
        this.setupAutoClear(notification);
      }
      return notification;
    },
    // Helper methods for each type of notification
    error(message, options) {
      return this.addNotification(notificationAssign({
        message: message,
        type: 'error'
      }, options));
    },
    success(message, options) {
      return this.addNotification(notificationAssign({
        message: message,
        type: 'success'
      }, options));
    },
    info(message, options) {
      return this.addNotification(notificationAssign({
        message: message,
        type: 'info'
      }, options));
    },
    warning(message, options) {
      return this.addNotification(notificationAssign({
        message: message,
        type: 'warning'
      }, options));
    },
    removeNotification(notification) {
      if (!notification) {
        return;
      }
      notification.set('dismiss', true);

      // Delay removal from DOM for dismissal animation
      _runloop.run.later(this, () => {
        this.content.removeObject(notification);
      }, 500);
    },
    setupAutoClear(notification) {
      notification.set('startTime', Date.now());
      const timer = _runloop.run.later(this, () => {
        // Hasn't been closed manually
        if (this.content.indexOf(notification) >= 0) {
          this.removeNotification(notification);
        }
      }, notification.get('remaining'));
      notification.set('timer', timer);
    },
    pauseAutoClear(notification) {
      _runloop.run.cancel(notification.get('timer'));
      const elapsed = Date.now() - notification.get('startTime');
      const remaining = notification.get('clearDuration') - elapsed;
      notification.set('remaining', remaining);
    },
    clearAll() {
      this.get('content').forEach(notification => {
        this.removeNotification(notification);
      });
      return this;
    },
    setDefaultAutoClear(autoClear) {
      (0, _object.set)(globals, 'autoClear', autoClear);
    },
    setDefaultClearDuration(clearDuration) {
      (0, _object.set)(globals, 'clearDuration', clearDuration);
    }
  });
});