define("ember-modal-dialog/templates/components/liquid-tether-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "nlOjIi93",
    "block": "[[[41,[33,1],[[[6,[39,2],null,[[\"stack\",\"class\"],[\"modal-overlay\",\"liquid-dialog-container\"]],[[\"default\"],[[[[1,\"    \"],[10,0],[15,0,[36,3]],[15,\"onclick\",[28,[37,4],[[30,0],[33,5]],null]],[14,\"tabindex\",\"-1\"],[14,\"data-emd-overlay\",\"\"],[12],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[]],null],[6,[39,6],null,[[\"class\",\"target\",\"attachment\",\"targetAttachment\",\"targetModifier\",\"classPrefix\",\"offset\",\"targetOffset\",\"constraints\",\"stack\",\"value\"],[[33,7],[33,8],[33,9],[33,10],[33,11],[33,12],[33,13],[33,14],[33,15],[33,16],[33,17]]],[[\"default\"],[[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]]]]]],[\"&default\"],false,[\"if\",\"hasOverlay\",\"liquid-wormhole\",\"overlayClassNamesString\",\"action\",\"onClickOverlay\",\"liquid-tether\",\"containerClassNamesString\",\"tetherTarget\",\"attachment\",\"targetAttachment\",\"targetModifier\",\"tetherClassPrefix\",\"offset\",\"targetOffset\",\"constraints\",\"stack\",\"value\",\"yield\"]]",
    "moduleName": "ember-modal-dialog/templates/components/liquid-tether-dialog.hbs",
    "isStrictMode": false
  });
});